@charset "utf-8";
/*
Template Name: Preevent
Author: <a href="http://www.os-templates.com/">OS Templates</a>
Author URI: http://www.os-templates.com/
Licence: Free to use under our free template licence terms
Licence URI: http://www.os-templates.com/template-terms
File: Layout CSS
*/

@import url("fontawesome-4.6.3.min.css");
@import url("framework.css");

/* Rows
--------------------------------------------------------------------------------------------------------------- */
.row0,
.row0 a {
}
.row1,
.row1 a {
}
.row2,
.row2 a {
}
.row3,
.row3 a {
}
.row4,
.row4 a {
}
.row5,
.row5 a {
}

/* Header
--------------------------------------------------------------------------------------------------------------- */
#header {
}

#header #logo {
  margin: 30px 0 0 0;
}
#header #logo h1 {
  margin: 0;
  padding: 0;
  font-size: 22px;
  text-transform: uppercase;
}

/* Page Intro
--------------------------------------------------------------------------------------------------------------- */
#pageintro {
  padding: 220px 0;
  text-align: center;
}

#pageintro .heading {
  display: block;
  position: relative;
  margin: 0 0 50px 0;
  padding: 0 0 30px 0;
  font-size: 64px;
}
#pageintro .heading::after {
  display: block;
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 120px;
  height: 2px;
  margin-left: -60px;
  content: "";
}
#pageintro .txtwrap {
  display: block;
  max-width: 65%;
  margin: 0 auto;
}
#pageintro p {
  margin: 0;
}
#pageintro footer {
  margin-top: 80px;
}

/* Content Area
--------------------------------------------------------------------------------------------------------------- */

/* Content */
.container .content {
}

.sectiontitle {
  display: block;
  margin-bottom: 80px;
}
.sectiontitle * {
  margin: 0;
}

.services {
}
.services li {
  padding: 40px 0 0 0;
  text-align: center;
}
.services li:last-child {
  margin-bottom: 0;
} /* Used when elements stack in small viewports */
.services li * {
}
.services li article {
  display: block;
  position: relative;
  padding: 65px 20px 25px 20px;
  border: 1px solid;
}
.services li article i {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 50%;
  width: 80px;
  height: 80px;
  line-height: 80px;
  margin: -40px 0 0 -40px;
  border-radius: 50%;
  font-size: 36px;
  z-index: 1;
}
.services li article .heading {
  font-size: 1.2rem;
}
.services li article footer {
  margin-top: 25px;
}

.element article {
  display: block;
  max-width: 320px;
}
.element article .txtwrap {
  padding: 20px 15px;
}
.element article .txtwrap * {
  margin: 0 0 15px 0;
  line-height: 1;
}
.element article .txtwrap .heading {
  margin-bottom: 5px;
  font-size: 1.2rem;
}
.element article .txtwrap em {
  display: block;
  font-size: 0.8rem;
  font-style: normal;
}
.element article .txtwrap em br {
  margin: 0;
}
.element article .txtwrap p {
  line-height: 1.4;
}
.element article .txtwrap footer,
.element article .txtwrap .btn {
  margin: 0;
}
.team.element article {
  text-align: center;
  border-bottom: 1px solid;
}
.team.element article .txtwrap em {
  text-transform: capitalize;
}

.iconlist {
  margin: 30px 0;
}
.iconlist li {
  display: block;
  margin-bottom: 15px;
  padding: 20px;
}
.iconlist li:last-child {
  margin-bottom: 0;
}
.iconlist li::before,
.iconlist li::after {
  display: table;
  content: "";
}
.iconlist li::after {
  clear: both;
}
.iconlist li > :first-child {
  float: left;
  margin-right: 5%;
}
.iconlist li > :last-child {
  margin-top: 17px;
}
.iconlist li * {
  margin: 0;
}
.iconlist li .heading {
  line-height: 1;
  font-size: 1.2rem;
}

.testimonial {
  margin: 30px 0;
}
.testimonial figure {
  margin-bottom: 20px;
}
.testimonial figure img {
  float: left;
  margin-right: 15px;
}
.testimonial figure figcaption {
  float: left;
  margin: 15px 0 0 0;
}
.testimonial figure figcaption * {
  display: block;
  margin: 0;
  padding: 0;
  line-height: 1;
}
.testimonial figure figcaption strong {
  margin-bottom: 5px;
}
.testimonial figure figcaption br {
  display: none;
}
.testimonial figure figcaption em {
  font-size: 0.8rem;
}
.testimonial blockquote {
  display: block;
  position: relative;
  width: 100%;
  margin: 0;
  padding: 30px 0 0 0;
  line-height: 1.4;
  z-index: 1;
}
.testimonial blockquote::before {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  font-family: "FontAwesome";
  font-size: 60px;
  content: "\f10d";
  z-index: -1;
}

/* Comments */
#comments ul {
  margin: 0 0 40px 0;
  padding: 0;
  list-style: none;
}
#comments li {
  margin: 0 0 10px 0;
  padding: 15px;
}
#comments .avatar {
  float: right;
  margin: 0 0 10px 10px;
  padding: 3px;
  border: 1px solid;
}
#comments address {
  font-weight: bold;
}
#comments time {
  font-size: smaller;
}
#comments .comcont {
  display: block;
  margin: 0;
  padding: 0;
}
#comments .comcont p {
  margin: 10px 5px 10px 0;
  padding: 0;
}

#comments form {
  display: block;
  width: 100%;
}
#comments input,
#comments textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid;
}
#comments textarea {
  overflow: auto;
}
#comments div {
  margin-bottom: 15px;
}
#comments input[type="submit"],
#comments input[type="reset"] {
  display: inline-block;
  width: auto;
  min-width: 150px;
  margin: 0;
  padding: 8px 5px;
  cursor: pointer;
}

/* Sidebar */
.container .sidebar {
}

.sidebar .sdb_holder {
  margin-bottom: 50px;
}
.sidebar .sdb_holder:last-child {
  margin-bottom: 0;
}

#footer {
  padding: 80px 0;
}

#footer .heading {
  margin: 0 0 50px 0;
  padding: 0;
  font-size: 1.2rem;
}

#footer .sectiontitle .heading {
  margin-bottom: 20px;
  font-size: 4rem;
}
#footer .sectiontitle ul {
  margin-bottom: 30px;
}

#footer .linklist li {
  display: block;
  margin-bottom: 15px;
  padding: 0 0 15px 0;
  border-bottom: 1px solid;
}
#footer .linklist li:last-child {
  margin: 0;
  padding: 0;
  border: none;
}
#footer .linklist li::before,
#footer .linklist li::after {
  display: table;
  content: "";
}
#footer .linklist li,
#footer .linklist li::after {
  clear: both;
}

#footer .contact {
}
#footer .contact.linklist li,
#footer .contact.linklist li:last-child {
  position: relative;
  padding-left: 40px;
}
#footer .contact li * {
  margin: 0;
  padding: 0;
  line-height: 1.6;
}
#footer .contact li i {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 30px;
  font-size: 16px;
  text-align: center;
}

#copyright {
  padding: 20px 0;
}
#copyright * {
  margin: 0;
  padding: 0;
}

*,
/* *::before,
*::after {
  transition: all 0.3s;
} */
#mainav form * {
  transition: none !important;
}

nav ul,
nav ol {
  margin: 0;
  padding: 0;
  list-style: none;
}

#mainav,
#breadcrumb,
.sidebar nav {
  line-height: normal;
}
#mainav .drop::after,
#mainav li li .drop::after,
#breadcrumb li a::after,
.sidebar nav a::after {
  position: absolute;
  font-family: "FontAwesome";
  font-size: 10px;
  line-height: 10px;
}

#mainav {
}
#mainav ul {
  text-transform: uppercase;
}
#mainav ul ul {
  z-index: 9999;
  position: absolute;
  width: 180px;
  text-transform: none;
}
#mainav ul ul ul {
  left: 180px;
  top: 0;
}
#mainav li {
  display: inline-block;
  position: relative;
  margin: 0 15px 0 0;
  padding: 0;
}
#mainav li:last-child {
  margin-right: 0;
}
#mainav li li {
  width: 100%;
  margin: 0;
}
#mainav li a {
  display: block;
  padding: 30px 0;
}
#mainav li li a {
  border: solid;
  border-width: 0 0 1px 0;
}
#mainav .drop {
  padding-left: 15px;
}
#mainav li li a,
#mainav li li .drop {
  display: block;
  margin: 0;
  padding: 10px 15px;
}
#mainav .drop::after,
#mainav li li .drop::after {
  content: "\f0d7";
}
#mainav .drop::after {
  top: 35px;
  left: 5px;
}
#mainav li li .drop::after {
  top: 15px;
  left: 5px;
}
#mainav ul ul {
  visibility: hidden;
  opacity: 0;
}
#mainav ul li:hover > ul {
  visibility: visible;
  opacity: 1;
}

#mainav form {
  display: none;
  margin: 0;
  padding: 0;
}
#mainav form select,
#mainav form select option {
  display: block;
  cursor: pointer;
  outline: none;
}
#mainav form select {
  width: 100%;
  padding: 5px;
  border: 1px solid;
}
#mainav form select option {
  margin: 5px;
  padding: 0;
  border: none;
}

#breadcrumb {
  padding: 120px 0 30px;
}
#breadcrumb ul {
  margin: 0 0 30px 0;
  padding: 0 0 8px 0;
  list-style: none;
  text-transform: uppercase;
  border-bottom: 1px solid;
}
#breadcrumb li {
  display: inline-block;
  margin: 0 6px 0 0;
  padding: 0;
}
#breadcrumb li a {
  display: block;
  position: relative;
  margin: 0;
  padding: 0 12px 0 0;
  font-size: 12px;
}
#breadcrumb li a::after {
  top: 3px;
  right: 0;
  content: "\f101";
}
#breadcrumb li:last-child a {
  margin: 0;
  padding: 0;
}
#breadcrumb li:last-child a::after {
  display: none;
}
#breadcrumb .heading {
  margin: 0;
  font-size: 2rem;
}

.sidebar nav {
  display: block;
  width: 100%;
}
.sidebar nav li {
  margin: 0 0 3px 0;
  padding: 0;
}
.sidebar nav a {
  display: block;
  position: relative;
  margin: 0;
  padding: 5px 10px 5px 15px;
  text-decoration: none;
  border: solid;
  border-width: 0 0 1px 0;
}
.sidebar nav a::after {
  top: 9px;
  left: 5px;
  content: "\f101";
}
.sidebar nav ul ul a {
  padding-left: 35px;
}
.sidebar nav ul ul a::after {
  left: 25px;
}
.sidebar nav ul ul ul a {
  padding-left: 55px;
}
.sidebar nav ul ul ul a::after {
  left: 45px;
}

.pagination {
  display: block;
  width: 100%;
  text-align: center;
  clear: both;
}
.pagination li {
  display: inline-block;
  margin: 0 2px 0 0;
}
.pagination li:last-child {
  margin-right: 0;
}
.pagination a,
.pagination strong {
  display: block;
  padding: 8px 11px;
  border: 1px solid;
  background-clip: padding-box;
  font-weight: normal;
}
.eventlist {
  display: flex;
  align-items: center;
  justify-content: center;
}
.eventlist li {
  margin-right: 1rem;
}

#backtotop {
  z-index: 999;
  display: inline-block;
  position: fixed;
  visibility: hidden;
  bottom: 20px;
  right: 20px;
  width: 36px;
  height: 36px;
  line-height: 36px;
  font-size: 16px;
  text-align: center;
  opacity: 0.2;
}
#backtotop i {
  display: block;
  width: 100%;
  height: 100%;
  line-height: inherit;
}
#backtotop.visible {
  visibility: visible;
  opacity: 0.5;
}
#backtotop:hover {
  opacity: 1;
}

table,
th,
td {
  border: 1px solid;
  border-collapse: collapse;
  vertical-align: top;
}
table,
th {
  table-layout: auto;
}
table {
  width: 100%;
  margin-bottom: 15px;
}
th,
td {
  padding: 5px 8px;
}
td {
  border-width: 0 1px;
}

#gallery {
  display: block;
  width: 100%;
  margin-bottom: 50px;
}
#gallery figure figcaption {
  display: block;
  width: 100%;
  clear: both;
}
#gallery li {
  margin-bottom: 30px;
}

body {
  /* color: #d3d3d3; */
  background-color: #212121;
}
a {
  color: #e5851f;
}
a:active,
a:focus {
  background: transparent;
}
hr,
.borderedbox {
  border-color: #d7d7d7;
}
label span {
  color: #ff0000;
  background-color: inherit;
}
input:focus,
textarea:focus,
*:required:focus {
  border-color: #e5851f;
}
.overlay {
  color: #ffffff;
  background-color: inherit;
}
.overlay::after {
  background-color: rgba(0, 0, 0, 0.3);
}
.overlay.light::after {
  background-color: rgba(255, 255, 255, 0.55);
}

.btn,
.btn.inverse:hover {
  color: #ffffff;
  background-color: #e5851f;
  border-color: #e5851f;
}
.btn:hover,
.btn.inverse {
  color: inherit;
  background-color: transparent;
  border-color: inherit;
}

/* Rows */
.row0,
.row0 a {
}
.row1 {
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0.3);
}
.row2 {
  color: #5f5f5f;
  background-color: #f7f7f7;
}
.row3 {
  color: #5f5f5f;
  background-color: #ffffff;
}
.row4 {
  color: #d3d3d3;
  background-color: #2d2d2d;
}
.row5,
.row5 a {
  color: #d3d3d3;
  background-color: #212121;
}

#topbar,
#topbar ul li {
  border-color: rgba(255, 255, 255, 0.3);
}
#topbar > div:last-of-type li:first-child a {
  color: #e5851f;
}

#header #logo a {
  color: inherit;
}

#pageintro .heading::after {
  background-color: #e5851f;
}

.services li article {
  border-color: #d7d7d7;
}
.services li article i {
  color: #ffffff;
  background: #d7d7d7;
}
.services li article:hover i {
  background: #e5851f;
}
.services li article:hover,
.services li article:hover i {
  box-shadow: 2px 2px rgba(0, 0, 0, 0.2);
}

.element article .txtwrap {
  color: #5f5f5f;
  background-color: #ffffff;
}
.team.element article {
  border-color: transparent;
}
.team.element article:hover {
  border-color: #e5851f;
}
.team.element article .txtwrap {
  color: inherit;
  background-color: #f7f7f7;
}

.iconlist li {
  background-color: rgba(255, 255, 255, 0.1);
}
.iconlist li a {
  color: inherit;
}
.iconlist li:hover {
  color: #5f5f5f;
  background-color: #ffffff;
}
.iconlist li:hover a i {
  color: #ffffff;
  background-color: #e5851f;
}

.testimonial blockquote::before {
  color: rgba(255, 255, 255, 0.1);
}

#footer .heading {
  color: #ffffff;
}
#footer hr,
#footer .borderedbox,
#footer .linklist li {
  border-color: rgba(255, 255, 255, 0.2);
}

#mainav li a {
  color: inherit;
}
#mainav .active a,
#mainav a:hover,
#mainav li:hover > a {
  color: #e5851f;
  background-color: inherit;
}
#mainav li li a,
#mainav .active li a {
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0.6);
  border-color: rgba(0, 0, 0, 0.6);
}
#mainav li li:hover > a,
#mainav .active .active > a {
  color: #ffffff;
  background-color: #e5851f;
}
#mainav form select {
  color: #ffffff;
  background-color: #000000;
  border-color: rgba(255, 255, 255, 0.3);
}

#breadcrumb ul {
  border-color: rgba(255, 255, 255, 0.2);
}
#breadcrumb a {
  color: inherit;
  background-color: inherit;
}
#breadcrumb li:last-child a {
  color: #e5851f;
}

.container .sidebar nav a {
  color: inherit;
  border-color: #d7d7d7;
}
.container .sidebar nav a:hover {
  color: #e5851f;
}

.pagination a,
.pagination strong {
  border-color: #d7d7d7;
}
.pagination .current * {
  color: #ffffff;
  background-color: #e5851f;
}

#backtotop {
  color: #ffffff;
  background-color: #e5851f;
}

table,
th,
td,
#comments .avatar,
#comments input,
#comments textarea {
  border-color: #d7d7d7;
}
#comments input:focus,
#comments textarea:focus,
#comments *:required:focus {
  border-color: #e5851f;
}
th {
  color: #ffffff;
  background-color: #373737;
}
tr,
#comments li,
#comments input[type="submit"],
#comments input[type="reset"] {
  color: inherit;
  background-color: #fbfbfb;
}
tr:nth-child(even),
#comments li:nth-child(even) {
  color: inherit;
  background-color: #f7f7f7;
}
table a,
#comments a {
  background-color: inherit;
}

@-ms-viewport {
  width: device-width;
}

@media screen and (min-width: 978px) {
  .hoc {
    max-width: 978px;
  }
}

@media screen and (max-width: 900px) {
  .hoc {
    max-width: 90%;
  }

  #header {
    padding: 30px 0;
  }
  #header #logo {
    margin-top: 3px;
  }

  #mainav {
  }
  #mainav ul {
    /* display: none; */
  }
  #mainav form {
    display: block;
  }

  #breadcrumb {
  }

  .container {
  }
  #comments input[type="reset"] {
    margin-top: 10px;
  }
  .pagination li {
    display: inline-block;
    margin: 0 5px 5px 0;
  }

  #footer {
  }

  #copyright {
  }
  #copyright p:first-of-type {
    margin-bottom: 10px;
  }
}

@media screen and (min-width: 750px) and (max-width: 900px) {
  .iconlist li > :last-child {
    margin-top: 8px;
  }
}

@media screen and (max-width: 750px) {
  .imgl,
  .imgr {
    display: inline-block;
    float: none;
    margin: 0 0 10px 0;
  }
  /* .fl_left,
  .fl_right {
    display: block;
    float: none;
  } */
  .group .group > *:last-child,
  .clear .clear > *:last-child,
  .clear .group > *:last-child,
  .group .clear > *:last-child {
    margin-bottom: 0;
  }
  .one_half,
  .one_third,
  .two_third,
  .one_quarter,
  .two_quarter,
  .three_quarter {
    display: block;
    float: none;
    width: auto;
    margin: 0 0 30px 0;
    padding: 0;
  }

  #header {
    text-align: center;
  }
  #header #logo {
    margin: 0 0 15px 0;
  }

  #pageintro {
    padding: 120px 0;
  }
  #pageintro .heading {
    font-size: 3rem;
  }
  #pageintro .txtwrap {
    max-width: none;
  }

  #footer {
    padding-bottom: 50px;
  }
}

@media screen and (max-width: 450px) {
  .iconlist li > :last-child {
    margin-top: 5px;
  }
}

@media screen and (max-width: 650px) {
  .eventlist {
    display: block;
  }
  .scrollable {
    display: block;
    width: 100%;
    margin: 0 0 30px 0;
    padding: 0 0 15px 0;
    overflow: auto;
    overflow-x: scroll;
  }
  .scrollable table {
    margin: 0;
    padding: 0;
    white-space: nowrap;
  }

  .inline li {
    display: block;
    margin-bottom: 10px;
  }
  .pushright li {
    margin-right: 0;
  }

  .font-x2 {
    font-size: 1.4rem;
  }
  .font-x3 {
    font-size: 1.6rem;
  }

  #pageintro .heading {
    font-size: 2rem;
  }
}
